import pinyin from "js-pinyin";
pinyin.setOptions({charCase: 1, checkPolyphone: false});
import { getCarTree } from "@/utils/request";

export default {
  state: {
    companyList: [],//公司列表 
    motorcadeList: [],//车队列表
    carList: [],//车辆列表
    carTree:[],//车辆树（公司-车队--车辆）
    carVideoChannelTree:[],//视频车辆树（公司-车队--车辆-通道）
    carVideoTree:[],//视频车辆树（公司-车队--车辆）
    motorcadeTree:[],//车队树（公司-车队）
    companyMap:{}, //公司map
    motorcadeMap:{},//车队map
    colorList:[], //车辆颜色
    carStatus:{}, //推送中每一条车辆状态变化
    carRegion:{}, // 车辆所属区域
  },
  mutations: {
    changeCompany(state,value){
      if (Array.isArray(value)){
        value.sort((a, b) => {
          let ap = pinyin.getCamelChars(a.name);
          let bp =  pinyin.getCamelChars(b.name)
          if (ap==bp){
            return 0
          }
          if (ap > bp){
            return 1;
          } else {
            return -1;
          }
        })
      }
      state.companyList = value;
    },
    changeMotorcade(state,value){ 
      if (Array.isArray(value)){
        value.sort((a, b) => {
          let ap = pinyin.getCamelChars(a.name);
          let bp =  pinyin.getCamelChars(b.name)
          if (ap==bp){
            return 0
          }
          if (ap > bp){
            return 1;
          } else {
            return -1;
          }
        })
      }
      state.motorcadeList = value; 
    },
    changeCar(state,value){ 
      if (Array.isArray(value)){
        sortChinese(value)
      }
      state.carList = value; 
    },
    changeCarRegion(state,value){ 
      state.carRegion = value; 
    },
    changeCompanyMap(state,value){
      state.companyMap = value; 
    },
    changeMotorcadeMap(state,value){
      state.motorcadeMap = value; 
    },
    changeMotorcadeTree(state,value){
      state.motorcadeTree = value; 
    },
    changeCarTree(state,value){
      state.carTree = value; 
    },
    changeCarVideoChannelTree(state,value){ //视频车辆树--带通道
      state.carVideoChannelTree = value; 
    },
    changeCarVideoTree(state,value){ //视频车辆树--不带通道
      state.carVideoTree = value; 
    },
    changeColor(state,value){
      state.colorList = value; 
    },
    
    carStatusFun(state,value){
      state.carStatus = value;
    },
    realTimeTableFun(state,value){ //实时定位数据
      state.realTimeTable = value;
    }
  },
  actions: {}
}
function sortChinese(arr){ // 参数：arr 排序的数组; dataLeven 数组内的需要比较的元素属性
  let tem = {"京": 0,"澳": 1,"川": 2,"滇": 3,"鄂": 4,"甘": 5,"赣": 6,"港": 7,"桂": 8,"贵": 9,"黑": 10,"沪": 10,"吉": 12,"冀": 13,"晋": 14,"津": 15,"辽": 16,"鲁": 17,"蒙": 18,"闽": 19,"宁": 20,"青": 21,"琼": 22,"苏": 23,"台": 24,"皖": 25,"陕": 26,"湘": 27,"新": 28,"豫": 29,"渝": 30,"粤": 31,"云": 3,"藏": 32,"浙": 33}
  arr.sort(function (a, b) {
    let a1 = a.car_no.substr(0,1)
    let b1 = b.car_no.substr(0,1)
    if (tem[a1]==undefined){
      return 1
    }
    if (tem[b1]==undefined){
      return -1
    }
    if (tem[a1] == tem[b1]){
      let a2 = a.car_no.substr(1)
      let b2 = b.car_no.substr(1)
      return a2.localeCompare(b2)
    }
    if (tem[a1] > tem[b1]){
      return 1;
    } else {
      return -1;
    }
  })
}
