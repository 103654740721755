import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
import './config'
import router from '@/router'
// import global from './global.js'
// import qs from 'qs'
// import coverUtils from './CoverUtils.js'

//创建axios实例
const service = axios.create({
	// baseURL: process.env.NODE_ENV === "development"?window.configs.host:'', //请求的基础路径
	baseURL: window.configs.host, //请求的基础路径
	timeout: 1000 * 1000, //请求的超时时间
})
console.log('process.env.NODE_ENV',process.env.NODE_ENV)
let tokenUpdateing = false,//token是否处于更新中
reqLists = []//更新token时请求的队列 
// request拦截器
service.interceptors.request.use(
	(config) => {
		let accessKey =localStorage.getItem("accessKey");
		if (accessKey){
			config.headers['Access-Key'] =accessKey;
		}
		let token =localStorage.getItem("token");
		config.headers['Access-Token'] =token?token:'';
		let refreshToken =localStorage.getItem("refreshToken");
		config.headers['Refresh-Token'] =refreshToken?refreshToken:'';
		let params = config.params ? config.params : {}
		config.params = params
		return config
	},
	(error) => {
		Promise.reject(error)
	}
)
// 响应拦截
service.interceptors.response.use(
	async  response => {
		const res = response.data
		if (0 != res.code) {
			// 接口认证失败，重新认证
			if (res.code === 401) {
				if (!tokenUpdateing) {
					tokenUpdateing = true
				    return RefreshToken(response,service)
				} else {
					// 返回未执行 resolve 的 Promise
					return new Promise(resolve => {
						// 用函数形式将 resolve 存入，等待刷新后再执行
						reqLists.push(() => {
							resolve(service(response.config))
						})
					})
				}
			}
			Message.error(res.msg);
		}
		return res

	},(error) => {
		return Promise.reject(error)
	}
)
/**
 * 刷新token
 */
function RefreshToken (response,service) {
    return service.post('/authorization?client_id=tg1023548521&grant_type=refresh_token').then(res => {
		if (res.code !=0){
			localStorage.removeItem("token");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("userNo");
			localStorage.removeItem("topAllMenus");
			localStorage.removeItem("roleIds");
			location.reload();
		} else {
			localStorage.setItem("token", res.data.accessToken);//当前登录用户的token
			localStorage.setItem("refreshToken", res.data.refreshToken);//当前登录用户的token

			// 已经刷新了token，将所有队列中的请求进行重试，最后再清空队列
			reqLists.forEach(run => run())
			reqLists = []
			
			return service(response.config)
		}

    }).catch(res => {
        localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("userNo");
		localStorage.removeItem("topAllMenus");
		localStorage.removeItem("roleIds");
		location.reload();
    }).finally(() => {
        tokenUpdateing = false
    })
}

/**
 *
 * @param method
 * @param url
 * @param params 参数在url后面拼接
 * @param data 参数在请求体
 * @param headers
 * @returns {Promise<any>}
 */
function apiAxios(method,baseUrl,url, params, data, headers) {
	let httpDefault = {
		method: method,
		baseURL: baseUrl,
		url: url,
		//设置默认请求头
		headers: {
			'content-type':
				headers == null || headers == ''
					? 'application/json;charset=utf-8'
					: headers,
		},
		params: params,
		data: data,
		timeout: 60000,
	}
	return new Promise((resolve, reject) => {
		service(httpDefault).then((res) => {
				resolve(res)
			})
			.catch((response) => {
				reject(response)
			})
	})
}
export default {
	install: function (Vue) {
		Vue.prototype.getAxios = ({
			url,
			params,
			data,
			headers,
			host = window.configs.host,
		}) => apiAxios('GET',host, url, params, JSON.stringify(data), headers)
		Vue.prototype.postAxios = ({
			url,
			params,
			data,
			headers,
			host = window.configs.host,
		}) => apiAxios('POST',host,  url, params, JSON.stringify(data), headers)
		Vue.prototype.postFormAxios = ({
			url,
			params,
			data,
			headers = 'application/x-www-form-urlencoded;charset=utf-8',
			host = window.configs.host,
		}) => apiAxios('POST',host, url, params, JSON.stringify(data), headers)
		Vue.prototype.putAxios = ({
			url,
			params,
			data,
			headers,
			host = window.configs.host,
		}) => apiAxios('PUT',host, url, params, JSON.stringify(data), headers)
		Vue.prototype.deleteAxios = ({
			url,
			params,
			data,
			headers,
			host = window.configs.host,
		}) =>apiAxios('DELETE',host, url, params, JSON.stringify(data), headers)
	},
	service,
}

// 根据免登陆ak获取用户信息
export function getUserbyAccessKey(){
	var accessKey = localStorage.getItem("accessKey");
	if (accessKey) {
		axios.post(window.configs.host+'/authorization?client_id=tg1023548521&grant_type=get_ak_user',null,{
			headers: { 
				'content-type':'application/json;charset=utf-8',
				'Access-Key':accessKey,
			}
		}).then(res=> {
			if (res.data.code === 0) {
				if (res.data.data.userId){
					localStorage.setItem("userId", res.data.data.userId);//当前登录用户id
					localStorage.setItem("companyName", res.data.data.companyName);
          			localStorage.setItem("companyLogo", res.data.data.companyLogo);
					localStorage.setItem("isAdmin", JSON.stringify(res.data.data.isAdmin))
					var form ={
						loginName: res.data.data.userNo,
						loginType:0,
					}
          			localStorage.setItem("loginForm", JSON.stringify(form));
				} else {
					Message.error("未读取到用户信息");
					router.go(-1)
				}
			} else {
				Message.error(res.data.msg);
				router.go(-1)
			}
		})
	}
}
// // 查询所有公司，车队，车辆
export function getBaseList(){
	axios.get(window.configs.host+'/business/get_auth_data',{
		headers: { 
			'content-type':'application/json;charset=utf-8',
			'Access-Key':localStorage.getItem("accessKey"),
			'Access-Token':localStorage.getItem("token"),
			'Refresh-Token':localStorage.getItem("refreshToken")
		},
	}).then(res=> {
		if (res.data.code === 0) {
			if(res.data.data.companys&&res.data.data.companys.length>0){
				res.data.data.companys.forEach(item=>{
					item.type='company';
					item.typeId="company"+item.id;
				})
			}
			if(res.data.data.motorcades&&res.data.data.motorcades.length>0){
				res.data.data.motorcades.forEach(item=>{
					item.type='motorcade';
					item.typeId="motorcade"+item.id;
				})
			}
			// var carStatueMap = new Map(); //车辆状态存储  推送车辆状态实时更新 实时定位表格状态
			if(res.data.data.cars&&res.data.data.cars.length>0){
				res.data.data.cars.forEach(item=>{
					// let key = item.iccid;
					// carStatueMap.set(key, item.status);
					item.type='car';
					item.typeId="car"+item.id;
				})
			}
			store.commit('changeCompany',res.data.data.companys);
			store.commit('changeMotorcade',res.data.data.motorcades);
			store.commit('changeCar',res.data.data.cars);
			companyTree();
			getCarTree();
			getCarVideoChannelTree(); //实时视频左侧树
			getCarVideoTree(); //实时视频左侧树
		}
	})
}
// 公司  车队树
export  function companyTree(){
	let companys = store.state.baseData.companyList;
	let motorcades = store.state.baseData.motorcadeList; 
	var motorcadeMap = new Map();
	var companyMap = new Map();
	var companyTree=[];
	motorcadeMap.clear();
	motorcades.forEach((item) => {
		let key = item.cid;
		let obj = {pid: item.cid, id: item.id, name: item.name,type:item.type,typeId:item.typeId };
		let values = [];
		if (motorcadeMap.has(key)) {
		values = motorcadeMap.get(key);
		} else {
		values = [];
		}
		values.push(obj);
		motorcadeMap.set(key, values);
	}); 
	store.commit('changeMotorcadeMap',motorcadeMap);
	companys.forEach((item) => { 
		let obj = { id: item.id, name: item.name,pid:0,type:item.type,typeId:item.typeId };
		var children = motorcadeMap.get(item.id);
		if(children && children.length>0){
			obj.children = children;
		}
		companyTree.push(obj);
		companyMap.set(item.id,obj);
	});
	store.commit('changeCompanyMap',companyMap);
	store.commit('changeMotorcadeTree',companyTree);
}
// 公司 车队 车辆树
export function getCarTree(){
	let companys = store.state.baseData.companyList;
	let motorcades = store.state.baseData.motorcadeList; 
	let cars = store.state.baseData.carList;

	var company_carMap = new Map();  //公司下面的车辆
	var motorcade_carMap = new Map();  //车队下面的车辆

	var motorcadeMap = new Map();
	// 先区分是公司下面的车辆 还是车队下面的车辆
	company_carMap.clear();
	motorcade_carMap.clear();
	cars.forEach(item=>{
		//location字段表示车辆是在公司下面还是在车队下面
		if(item.motorcace_id!=0){ //车队  
			item.pid=item.motorcace_id;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,alias:item.alias,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type,location:'motorcace' };
			let values = [];
			if (motorcade_carMap.has(key)) {
				values = motorcade_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			motorcade_carMap.set(key, values);
		}else{ //公司
			item.pid=item.business;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,alias:item.alias,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type,location:'company' };
			let values = [];
			if (company_carMap.has(key)) {
				values = company_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			company_carMap.set(key, values);
		}
	})
	motorcadeMap.clear();
	var motorcadeNew=[];
	motorcades.forEach((item) => {
		// 给车辆插入到车队下面
		let obj = { id: item.id, name: item.name,pid:item.cid,typeId:item.typeId,type:item.type };
		var children = motorcade_carMap.get(item.id);
		if(children && children.length>0){
			obj.children = children;
			var onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
			children.forEach(mm=>{
				if(mm.acc==1){
					accOpenNum++
				}else if(mm.acc==0){
					accCloseNum++
				}
				if(mm.status!=0){
					onlineNum++;
				}
				if(mm.status==1){//在线行驶
					runNum++;
				}else if(mm.status==2){// 报警行驶
					runNum++;
					alarmNum++;
				}else if(mm.status==3){// 在线停车
					stopNum++;
				}else if(mm.status==4){// 停车报警
					stopNum++;
					alarmNum++;
				}else if(mm.status==5){// 报无效定位
					invalidNum++;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=children.length;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		motorcadeNew.push(obj);
	});
	// return
	// 创建车队map
	motorcadeNew.forEach(item=>{
		let key = item.pid;
		let obj = { id: item.id, name: item.name,pid:item.pid,children:item.children,typeId:item.typeId,type:item.type,
			onlineNum:item.onlineNum,allNum:item.allNum,
			runNum:item.runNum,stopNum:item.stopNum,
			alarmNum:item.alarmNum,invalidNum:item.invalidNum,
			accOpenNum:item.accOpenNum,accCloseNum:item.accCloseNum
		};
		let values = [];
		if (motorcadeMap.has(key)) {
		values = motorcadeMap.get(key);
		} else {
		values = [];
		}
		values.push(obj);
		motorcadeMap.set(key, values);
	})
	var carTree=[];
	// 给车队加入到公司
	companys.forEach((item) => { 
		let obj = { id: item.id, name: item.name,pid:0,typeId:item.typeId,type:item.type };
		var child1 = motorcadeMap.get(item.id)?motorcadeMap.get(item.id):[];
		var child2 = company_carMap.get(item.id)?company_carMap.get(item.id):[];
		var children=child1.concat(child2);		
		if(children && children.length>0){
			var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
			obj.children = children;
			children.forEach(mm=>{
				if(mm.type=='car'){
					if(mm.acc==1){
						accOpenNum++
					}else if(mm.acc==0){
						accCloseNum++
					}
					if(mm.status!=0){
						onlineNum++;
					}
					if(mm.status==1){//在线行驶
						runNum++;
					}else if(mm.status==2){// 报警行驶
						runNum++;
						alarmNum++;
					}else if(mm.status==3){// 在线停车
						stopNum++;
					}else if(mm.status==4){// 停车报警
						stopNum++;
						alarmNum++;
					}else if(mm.status==5){// 报无效定位
						invalidNum++;
					}
				}else{ //车队 需要累加车队下面的数据
					count=count+mm.allNum;
					onlineNum=onlineNum+mm.onlineNum;
					runNum=runNum+mm.runNum;
					stopNum=stopNum+mm.stopNum;
					alarmNum=alarmNum+mm.alarmNum;
					invalidNum=invalidNum+mm.invalidNum;
					accOpenNum=accOpenNum+mm.accOpenNum;
					accCloseNum=accCloseNum+mm.accCloseNum;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=child2.length+count;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		carTree.push(obj);
	});
	var newTree=[];
	let obj = {
		id: 999999, 
		name: '车辆监控中心',
		pid:0,
		children:carTree,
		type:'root',
		typeId:'root999999'
	};
	if(carTree.length>0){
		var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
		carTree.forEach(item=>{
			count=count+item.allNum;
			onlineNum=onlineNum+item.onlineNum;
			runNum=runNum+item.runNum;
			stopNum=stopNum+item.stopNum;
			alarmNum=alarmNum+item.alarmNum;
			invalidNum=invalidNum+item.invalidNum;
			accOpenNum=accOpenNum+item.accOpenNum;
			accCloseNum=accCloseNum+item.accCloseNum;
		})
		obj.onlineNum=onlineNum;
		obj.runNum=runNum;
		obj.stopNum=stopNum;
		obj.alarmNum=alarmNum;
		obj.invalidNum=invalidNum;
		obj.accOpenNum=accOpenNum;
		obj.accCloseNum=accCloseNum;
		obj.allNum=count;
	}else{
		obj.onlineNum=0;
		obj.allNum=0;
		obj.runNum=0;
		obj.stopNum=0;
		obj.alarmNum=0;
		obj.invalidNum=0;
		obj.accOpenNum=0;
		obj.accCloseNum=0;
	}
	newTree.push(obj);
	store.commit('changeCarTree',newTree);
}
// 查询车辆所属区域
export function getCarRegion(){
	axios.get(window.configs.host+'/business/car/region',{
		headers: { 
			'content-type':'application/json;charset=utf-8',
			'Access-Key':localStorage.getItem("accessKey"),
			'Access-Token':localStorage.getItem("token"),
			'Refresh-Token':localStorage.getItem("refreshToken")
		},
	}).then(res=> {
		if (res.data.code === 0) {
			if(res.data.data&&res.data.data.length>0){
				let region = {}
				res.data.data.forEach(item=>{
					if(!!item.area){
						region[item.id] = item.area;
					}
				})
				store.commit('changeCarRegion',region);
			}
		}
	})
}
// 视频====公司  车队  车辆 通道
export function getCarVideoChannelTree(){
	let companys = store.state.baseData.companyList;
	let motorcades = store.state.baseData.motorcadeList; 
	let carArr=store.state.baseData.carList;
	let cars = [];//编辑出视频车辆（视频和Ai设备）
	if(carArr.length>0){
		carArr.forEach(item=>{
			if(item.equipment_type=='2'||item.equipment_type=='3'){
				if (item.channel_type){
					item.children=[];
					for(var v in item.channel_type){
						var pid=null
						if(item.motorcace_id){
							pid=item.motorcace_id;
						}else{
							pid=item.business;
						}
						let came = showPrefixImg(item.channel_type[v].type);
						item.children.push({
							car_id:item.id,
							name: item.channel_type[v].alias,
							car_no:item.car_no,
							channel:v,
							pid: pid,
							type:'channel',
							typeId:'channel'+item.iccid+v,
							id: item.channel_type[v].id,
							iccid:item.iccid,
							status:item.status,
							className: came,
                  			closeClassName: came,
						});
					}
				}
				cars.push(item)
			}
		})
	}
	var company_carMap = new Map();  //公司下面的车辆
	var motorcade_carMap = new Map();  //车队下面的车辆

	var motorcadeMap = new Map();
	// 先区分是公司下面的车辆 还是车队下面的车辆
	company_carMap.clear();
	motorcade_carMap.clear();
	cars.forEach(item=>{
		if(item.motorcace_id!=0){ //车队
			item.pid=item.motorcace_id;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type,children:item.children };
			let values = [];
			if (motorcade_carMap.has(key)) {
				values = motorcade_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			motorcade_carMap.set(key, values);
		}else{ //公司
			item.pid=item.business;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type,children:item.children };
			let values = [];
			if (company_carMap.has(key)) {
				values = company_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			company_carMap.set(key, values);
		}
	})
	motorcadeMap.clear();
	var motorcadeNew=[];
	motorcades.forEach((item) => {
		// 给车辆插入到车队下面
		let obj = { id: item.id, name: item.name,pid:item.cid,typeId:item.typeId,type:item.type };
		var children = motorcade_carMap.get(item.id);
		if(children && children.length>0){
			obj.children = children;
			var onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
			children.forEach(mm=>{
				if(mm.status!=0){
					onlineNum++;
					if(mm.acc==1){
						accOpenNum++
					}else if(mm.acc==0){
						accCloseNum++
					}
				}
				if(mm.status==1){//在线行驶
					runNum++;
				}else if(mm.status==2){// 报警行驶
					runNum++;
					alarmNum++;
				}else if(mm.status==3){// 在线停车
					stopNum++;
				}else if(mm.status==4){// 停车报警
					stopNum++;
					alarmNum++;
				}else if(mm.status==5){// 报无效定位
					invalidNum++;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=children.length;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		motorcadeNew.push(obj);
	});
	// return
	// 创建车队map
	motorcadeNew.forEach(item=>{
		let key = item.pid;
		let obj = { id: item.id, name: item.name,pid:item.pid,children:item.children,typeId:item.typeId,type:item.type,
			onlineNum:item.onlineNum,allNum:item.allNum,
			runNum:item.runNum,stopNum:item.stopNum,
			alarmNum:item.alarmNum,invalidNum:item.invalidNum,
			accOpenNum:item.accOpenNum,accCloseNum:item.accCloseNum
		};
		let values = [];
		if (motorcadeMap.has(key)) {
		values = motorcadeMap.get(key);
		} else {
		values = [];
		}
		values.push(obj);
		motorcadeMap.set(key, values);
	})
	var carVideoTree=[];
	// 给车队加入到公司
	companys.forEach((item) => { 
		let obj = { id: item.id, name: item.name,pid:0,typeId:item.typeId,type:item.type };
		var child1 = motorcadeMap.get(item.id)?motorcadeMap.get(item.id):[];
		var child2 = company_carMap.get(item.id)?company_carMap.get(item.id):[];
		var children=child1.concat(child2)
		var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
		if(children && children.length>0){
			obj.children = children;
			children.forEach(mm=>{
				if(mm.type=='car'){
					if(mm.status!=0){
						onlineNum++;
						if(mm.acc==1){
							accOpenNum++
						}else if(mm.acc==0){
							accCloseNum++
						}
					}
					if(mm.status==1){//在线行驶
						runNum++;
					}else if(mm.status==2){// 报警行驶
						runNum++;
						alarmNum++;
					}else if(mm.status==3){// 在线停车
						stopNum++;
					}else if(mm.status==4){// 停车报警
						stopNum++;
						alarmNum++;
					}else if(mm.status==5){// 报无效定位
						invalidNum++;
					}
				}else{
					count=count+mm.allNum;
					onlineNum=onlineNum+mm.onlineNum;
					runNum=runNum+mm.runNum;
					stopNum=stopNum+mm.stopNum;
					alarmNum=alarmNum+mm.alarmNum;
					invalidNum=invalidNum+mm.invalidNum;
					accOpenNum=accOpenNum+mm.accOpenNum;
					accCloseNum=accCloseNum+mm.accCloseNum;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=child2.length+count;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		carVideoTree.push(obj);
	});
	var newTree=[];
	let obj = {
		id: 999999, 
		name: '车辆监控中心',
		pid:0,
		children:carVideoTree,
		type:'root',
		typeId:'root999999'
	};
	if(carVideoTree.length>0){
		var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
		carVideoTree.forEach(item=>{
			count=count+item.allNum;
			onlineNum=onlineNum+item.onlineNum;
			runNum=runNum+item.runNum;
			stopNum=stopNum+item.stopNum;
			alarmNum=alarmNum+item.alarmNum;
			invalidNum=invalidNum+item.invalidNum;
			accOpenNum=accOpenNum+item.accOpenNum;
			accCloseNum=accCloseNum+item.accCloseNum;
		})
		obj.onlineNum=onlineNum;
		obj.runNum=runNum;
		obj.stopNum=stopNum;
		obj.alarmNum=alarmNum;
		obj.invalidNum=invalidNum;
		obj.accOpenNum=accOpenNum;
		obj.accCloseNum=accCloseNum;
		obj.allNum=count;
	}else{
		obj.onlineNum=0;
		obj.allNum=0;
		obj.runNum=0;
		obj.stopNum=0;
		obj.alarmNum=0;
		obj.invalidNum=0;
		obj.accOpenNum=0;
		obj.accCloseNum=0;
	}
	newTree.push(obj);
	store.commit('changeCarVideoChannelTree',newTree);
}
export function getCarVideoTree(){
	let companys = store.state.baseData.companyList;
	let motorcades = store.state.baseData.motorcadeList; 
	let carArr=store.state.baseData.carList;
	let cars = [];//编辑出视频车辆（视频和Ai设备）
	if(carArr.length>0){
		carArr.forEach(item=>{
			if(item.equipment_type=='2'||item.equipment_type=='3'){
				cars.push(item);
			}
		})
	}
	var company_carMap = new Map();  //公司下面的车辆
	var motorcade_carMap = new Map();  //车队下面的车辆

	var motorcadeMap = new Map();
	// 先区分是公司下面的车辆 还是车队下面的车辆
	company_carMap.clear();
	motorcade_carMap.clear();
	cars.forEach(item=>{
		if(item.motorcace_id!=0){ //车队
			item.pid=item.motorcace_id;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type };
			let values = [];
			if (motorcade_carMap.has(key)) {
				values = motorcade_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			motorcade_carMap.set(key, values);
		}else{ //公司
			item.pid=item.business;
			let key = item.pid;
			let obj = { id: item.id, name: item.car_no,iccid:item.iccid,status:item.status,
				acc:item.acc,car_no_color:item.car_no_color,user_name:item.user_name,area:item.area,terminal:item.terminal,equipment_type:item.equipment_type,
				motorcace_id:item.motorcace_id,business:item.business,pid:item.pid,typeId:item.typeId,type:item.type,};
			let values = [];
			if (company_carMap.has(key)) {
				values = company_carMap.get(key);
			} else {
				values = [];
			}
			values.push(obj);
			company_carMap.set(key, values);
		}
	})
	motorcadeMap.clear();
	var motorcadeNew=[];
	motorcades.forEach((item) => {
		// 给车辆插入到车队下面
		let obj = { id: item.id, name: item.name,pid:item.cid,typeId:item.typeId,type:item.type };
		var children = motorcade_carMap.get(item.id);
		if(children && children.length>0){
			obj.children = children;
			var onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
			children.forEach(mm=>{
				if(mm.status!=0){
					onlineNum++;
					if(mm.acc==1){
						accOpenNum++
					}else if(mm.acc==0){
						accCloseNum++
					}
				}
				if(mm.status==1){//在线行驶
					runNum++;
				}else if(mm.status==2){// 报警行驶
					runNum++;
					alarmNum++;
				}else if(mm.status==3){// 在线停车
					stopNum++;
				}else if(mm.status==4){// 停车报警
					stopNum++;
					alarmNum++;
				}else if(mm.status==5){// 报无效定位
					invalidNum++;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=children.length;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		motorcadeNew.push(obj);
	});
	// return
	// 创建车队map
	motorcadeNew.forEach(item=>{
		let key = item.pid;
		let obj = { id: item.id, name: item.name,pid:item.pid,children:item.children,typeId:item.typeId,type:item.type,
			onlineNum:item.onlineNum,allNum:item.allNum,
			runNum:item.runNum,stopNum:item.stopNum,
			alarmNum:item.alarmNum,invalidNum:item.invalidNum,
			accOpenNum:item.accOpenNum,accCloseNum:item.accCloseNum
		};
		let values = [];
		if (motorcadeMap.has(key)) {
		values = motorcadeMap.get(key);
		} else {
		values = [];
		}
		values.push(obj);
		motorcadeMap.set(key, values);
	})
	var carVideoTree=[];
	// 给车队加入到公司
	companys.forEach((item) => { 
		let obj = { id: item.id, name: item.name,pid:0,typeId:item.typeId,type:item.type };
		var child1 = motorcadeMap.get(item.id)?motorcadeMap.get(item.id):[];
		var child2 = company_carMap.get(item.id)?company_carMap.get(item.id):[];
		var children=child1.concat(child2)
		var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
		if(children && children.length>0){
			obj.children = children;
			children.forEach(mm=>{
				if(mm.type=='car'){
					if(mm.status!=0){
						onlineNum++;
						if(mm.acc==1){
							accOpenNum++
						}else if(mm.acc==0){
							accCloseNum++
						}
					}
					if(mm.status==1){//在线行驶
						runNum++;
					}else if(mm.status==2){// 报警行驶
						runNum++;
						alarmNum++;
					}else if(mm.status==3){// 在线停车
						stopNum++;
					}else if(mm.status==4){// 停车报警
						stopNum++;
						alarmNum++;
					}else if(mm.status==5){// 报无效定位
						invalidNum++;
					}
				}else{
					count=count+mm.allNum;
					onlineNum=onlineNum+mm.onlineNum;
					runNum=runNum+mm.runNum;
					stopNum=stopNum+mm.stopNum;
					alarmNum=alarmNum+mm.alarmNum;
					invalidNum=invalidNum+mm.invalidNum;
					accOpenNum=accOpenNum+mm.accOpenNum;
					accCloseNum=accCloseNum+mm.accCloseNum;
				}
			})
			obj.onlineNum=onlineNum;
			obj.runNum=runNum;
			obj.stopNum=stopNum;
			obj.alarmNum=alarmNum;
			obj.invalidNum=invalidNum;
			obj.accOpenNum=accOpenNum;
			obj.accCloseNum=accCloseNum;
			obj.allNum=child2.length+count;
		}else{
			obj.onlineNum=0;
			obj.allNum=0;
			obj.runNum=0;
			obj.stopNum=0;
			obj.alarmNum=0;
			obj.invalidNum=0;
			obj.accOpenNum=0;
			obj.accCloseNum=0;
		}
		carVideoTree.push(obj);
	});
	var newTree=[];
	let obj = {
		id: 999999, 
		name: '车辆监控中心',
		pid:0,
		children:carVideoTree,
		type:'root',
		typeId:'root999999'
	};
	if(carVideoTree.length>0){
		var count=0,onlineNum=0,runNum=0,stopNum=0,alarmNum=0,invalidNum=0,accOpenNum=0,accCloseNum=0;
		carVideoTree.forEach(item=>{
			count=count+item.allNum;
			onlineNum=onlineNum+item.onlineNum;
			runNum=runNum+item.runNum;
			stopNum=stopNum+item.stopNum;
			alarmNum=alarmNum+item.alarmNum;
			invalidNum=invalidNum+item.invalidNum;
			accOpenNum=accOpenNum+item.accOpenNum;
			accCloseNum=accCloseNum+item.accCloseNum;
		})
		obj.onlineNum=onlineNum;
		obj.runNum=runNum;
		obj.stopNum=stopNum;
		obj.alarmNum=alarmNum;
		obj.invalidNum=invalidNum;
		obj.accOpenNum=accOpenNum;
		obj.accCloseNum=accCloseNum;
		obj.allNum=count;
	}else{
		obj.onlineNum=0;
		obj.allNum=0;
		obj.runNum=0;
		obj.stopNum=0;
		obj.alarmNum=0;
		obj.invalidNum=0;
		obj.accOpenNum=0;
		obj.accCloseNum=0;
	}
	newTree.push(obj);
	store.commit('changeCarVideoTree',newTree);
}
// 根据视频通道的不同，展示不同的前缀图片
export function showPrefixImg(type){
	if (type.length==0){
	  return 'videoIcon_close1'
	}
	if (type.includes("2")||type.includes("1")){
	  return 'videoIcon_close4'
	}  
	if (type.includes("3")){
	  return 'videoIcon_close3'
	} 
	if (type.includes("0")) {
	  return 'videoIcon_close2'
	}
}
// 调用高德逆地理解析，支持批量,  type表示有些页面会有开始和结束位置（报表--行车统计分析，监管报表-凌晨营运车辆）
export function getAddressBatchFun(data,type,callback){
	// 组装字典，这样直接可以去重，key：经纬度，value:解析出来的地址
	let lngLatDic = {};
	if(type=='start'){
		data.forEach(item=>{
			// 过滤经纬度不能为0
			if((item.gflatitude&&item.gflongitude)&&(item.gflatitude!=0&&item.gflongitude!=0)){
				lngLatDic[item.gflongitude + "," + item.gflatitude] = "";
			}
			// 回显先赋值为空
			item.flocation = ""
		})
	}else if(type=='end'){
		data.forEach(item=>{
			if((item.gllatitude&&item.gllongitude)&&(item.gllatitude!=0&&item.gllongitude!=0)){
				lngLatDic[item.gllongitude + "," + item.gllatitude] = "";
			}
			// 回显先赋值为空
			item.llocation = ""
		})
	}else{
		data.forEach(item=>{
			if((item.glatitude&&item.glongitude)&&(item.glatitude!=0&&item.glongitude!=0)){
				lngLatDic[item.glongitude + "," + item.glatitude] = "";
			}
			// 回显先赋值为空
			item.location = ""
		})
	}
	// 开始批量请求获取地址，每次请求最多20个
	var j = 0;
	var coordinates = []
	for (let key in lngLatDic) {
		if (lngLatDic.hasOwnProperty(key)) {
			coordinates.push(key)
		  if ((j + 1) % 20 == 0) {
			BatchRequest(lngLatDic,coordinates,data,type,callback)
			coordinates = []
		  }
		  j ++ 
		}
	}
	// 不足20条的，再次请求一次
	BatchRequest(lngLatDic,coordinates,data,type,callback)
	coordinates = null
}
function BatchRequest(lngLatDic,coordinates,data,type,callback){
	// 检查坐标点是否为空
	if(coordinates == null && coordinates.length == 0){
		return
	}
	// 拼接字符串
	var loc = coordinates.join("|")
	if (loc == ""){
		return
	}
	// 动态切换不同的KEY
	var key = "key" + getRandomNumberBetween(1,3);
	// 请求接口
	axios.get(window.configs.domain + '/geocode/v3/'+key+'/regeo',{
		params:{
			location:loc,
		}
	}).then(res=> {
		if(res.data.status=="1"){
			// 解析地址
			res.data.regeocodes.forEach((geocode,ind)=>{
				var formatted_address = geocode.formatted_address
				if(Array.isArray(geocode.pois)&&geocode.pois.length>0){
					if(geocode.pois[0].name){
						// 地址去重
						formatted_address = formatted_address.replace(geocode.pois[0].name,"")
						formatted_address=formatted_address+geocode.pois[0].name
					}
					if(geocode.pois[0].direction){
						formatted_address=formatted_address+geocode.pois[0].direction
					}
					if(geocode.pois[0].distance){
						formatted_address=formatted_address+geocode.pois[0].distance+ "米"
					}
				}
				var k = coordinates[ind]
				lngLatDic[k] = formatted_address
			})
			// 没有数据，不回显
			if(res.data.regeocodes.length == 0){
				return
			}
			// 回显地址
			data.forEach(item=>{
				if(type=='start'){
					if(lngLatDic.hasOwnProperty(item.gflongitude + "," + item.gflatitude)) {
						item.flocation = lngLatDic[item.gflongitude + "," + item.gflatitude]
					} else {
						item.flocation = ""
					}
					item.location = item.flocation + "/"
				}else if(type=='end'){
					if(lngLatDic.hasOwnProperty(item.gllongitude + "," + item.gllatitude)) {
						item.llocation = lngLatDic[item.gllongitude + "," + item.gllatitude]
					} else {
						item.llocation = ""
					}
					item.location = item.location + item.llocation
				}else{
					if(lngLatDic.hasOwnProperty(item.glongitude + "," + item.glatitude)) {
						item.location = lngLatDic[item.glongitude + "," + item.glatitude]
					} else {
						item.location = ""
					}
				}
			})
			if(callback){
				callback(data);
			} 
		}
	}).catch(err =>{
		
	})
}

function getRandomNumberBetween(min,max){
    return Math.floor(Math.random()*(max-min+1)+min);
}
