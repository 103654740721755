export default {
    state: {
        //地图对象
        realMapObj: {},
        realMapType:'baidu',
        driverLineMapObj: {},
        driverLineMapType:'baidu',
        realVideoMapObj:{},
        realVideoMapType:'baidu',
        headerAlarmMapObj:{},
        headerAlarmMapType:'baidu',
        areaBaseMapObj:{},
        areaBaseMapType:'baidu',
        videoReplayMapObj:{},
        videoReplayMapType:'baidu',
        reportLineMapObj:{},
        reportLineMapType:'baidu',
    },
    getters: {
        realMapObj: state => state.realMapObj, //实时定位
        realMapType: state => state.realMapType,
        driverLineMapObj: state => state.driverLineMapObj,  //轨迹回放
        driverLineMapType: state => state.driverLineMapType,
        realVideoMapObj: state => state.realVideoMapObj,  //实时视频
        realVideoMapType: state => state.realVideoMapType,
        headerAlarmMapObj: state => state.headerAlarmMapObj,  //头部报警处理弹框
        headerAlarmMapType: state => state.headerAlarmMapType,
        areaBaseMapObj: state => state.areaBaseMapObj,  //区域管理
        areaBaseMapType: state => state.areaBaseMapType,
        videoReplayMapObj: state => state.videoReplayMapObj,  //录像回放
        videoReplayMapType: state => state.videoReplayMapType,
        reportLineMapObj: state => state.reportLineMapObj,  //报表查询---历史位置
        reportLineMapType: state => state.reportLineMapType,
    },
    mutations: {
        saveRealMapObj(state, data) {//实时定位
            state.realMapObj = data;
        },
        saveRealMapType(state, data) {//实时定位
            state.realMapType = data;
        },
        saveDriverLineMapObj(state, data) { //轨迹回放
            state.driverLineMapObj = data;
        },
        saveDriverLineMapType(state, data) {//轨迹回放
            state.driverLineMapType = data;
        },
        saveRealVideoMapObj(state, data) { //实时视频
            state.realVideoMapObj = data;
        },
        saveRealVideoMapType(state, data) {//实时视频
            state.realVideoMapType = data;
        },
        saveHeaderAlarmMapObj(state, data) { //头部报警处理弹框
            state.headerAlarmMapObj = data;
        },
        saveHeaderAlarmMapType(state, data) { //头部报警处理弹框
            state.headerAlarmMapType = data;
        },
        saveAreaBaseMapObj(state, data) { //区域管理
            state.areaBaseMapObj = data;
        },
        saveAreaBaseMapType(state, data) { //区域管理
            state.areaBaseMapType = data;
        },
        saveVideoReplayMapObj(state, data) { //录像回放
            state.videoReplayMapObj = data;
        },
        saveVideoReplayMapType(state, data) { //录像回放
            state.videoReplayMapType = data;
        },
        saveReportLineMapObj(state, data) { //报表查询---历史位置
            state.reportLineMapObj = data;
        },
        saveReportLineMapType(state, data) { 
            state.reportLineMapType = data;
        },
    },
    actions: {},
}
