import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//路由懒加载
function load(component) {
	return () =>
		import(/* webpackChunkName: "[request]" */ `@/views/${component}`)
}

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		meta: { title: '登录' },
		component: load('login/Login'),
	},
	{
		path: '/playVideo',
		name: 'playVideo',
		meta: { title: '视频播放' },
		component: load('zhjk/PlayVideo'),
	},
	{
		path: '/playVideoReplay',
		name: 'playVideoReplay',
		meta: { title: '录像播放' },
		component: load('zhjk/PlayVideoReplay'),
	},
	{
		path:'/redactList',
		name:"redactList",
		meta:{title:'跳转',dev:"1"},
		component:load('/home/redactList')
	},{// 综合监控
		path: '/main',
		name: 'zhjk',
		meta: { title: '综合监控' },
		component: load('Main'),
		children: [
			{
				path: '/realTime',
				name: 'realTime',
				meta: { title: '实时定位' }, 
				component: load('zhjk/RealTime'),  
			},{
				path: '/driveLineReplay',
				name: 'driveLineReplay',
				meta: { title: '轨迹回放' },
				component: load('zhjk/DriveLineReplay'),
			},{
				path: '/moreCarTrajectory',
				name: 'moreCarTrajectory',
				meta: { title: '多车轨迹' },
				component: load('zhjk/MoreCarTrajectory'),
			},{
				path: '/realVideo',
				name: 'realVideo',
				meta: { title: '实时视频' },
				component: load('zhjk/RealVideo'),
			},{
				path: '/videoReplay',
				name: 'videoReplay',
				meta: { title: '录像回放' },
				component: load('zhjk/VideoReplay'),
			},{
				path: '/videoFile',
				name: 'videoFile',
				meta: { title: '录像文件' },
				component: load('zhjk/VideoFile'),
			}, {
				path: "/videoPolling",
				name: "videoPolling",
				meta: { title: "视频轮询" },
				component: load("zhjk/VideoPolling"),
			},{
				path: "/groupMonitor",
				name: "groupMonitor",
				meta: { title: "分组监控" },
				component: load("zhjk/GroupMonitor"),
			  },{
				path: '/areaManage',
				name: 'areaManage',
				meta: { title: '区域管理' },
				component: load('zhjk/AreaManage'),
			},{
				path: '/issued',
				name: 'issued',
				meta: { title: '指令下发' },
				redirect: '/chemensuo',
				component: load("zhjk/Issued"),
				children: [
				{
					path: "/inIssuedSend",
					name: "inIssuedSend",
					meta: { title: "指令下发" },
					component: load("zhjk/issued/InIssuedSend"),
					children: [{//终端控制指令
						path: '/chemensuo',
						name: 'chemensuo',
						meta: { title: '车门锁控制' },
						component: load(
							'zhjk/issued/zdkzzl/Chemensuo'
						),
					},{
						path: '/zhongduankaiguan',
						name: 'zhongduankaiguan',
						meta: { title: '终端开关控制' },
						component: load(
							'zhjk/issued/zdkzzl/Zhongduankaiguan'
						),
					},{
						path: '/lianjiefuwu',
						name: 'lianjiefuwu',
						meta: { title: '连接服务器' },
						component: load(
							'zhjk/issued/zdkzzl/Lianjiefuwuqi'
						),
					},{
						path: '/wuxianshengji',
						name: 'wuxianshengji',
						meta: { title: '无线升级' },
						component: load(
							'zhjk/issued/zdkzzl/Wuxianshengji'
						),
					},{
						path: '/youdiankongzhi',
						name: 'youdiankongzhi',
						meta: { title: '油电控制' },
						component: load(
							'zhjk/issued/zdkzzl/Youdiankongzhi'
						),
					},{//终端参数指令
						path: '/tongxun',
						name: 'tongxun',
						meta: { title: '通讯连接参数' },
						component: load(
							'zhjk/issued/zdzlcs/Tongxun'
						),
					},{
						path: '/fuwuqicanshu',
						name: 'fuwuqicanshu',
						meta: { title: '服务器参数' },
						component: load(
							'zhjk/issued/zdzlcs/Fuwuqicanshu'
						),
					},{
						path: '/dianhuahaoma',
						name: 'dianhuahaoma',
						meta: { title: '电话号码参数' },
						component: load(
							'zhjk/issued/zdzlcs/Dianhuahaoma'
						),
					},{
						path: '/weizhihuibao',
						name: 'weizhihuibao',
						meta: { title: '位置汇报参数' },
						component: load(
							'zhjk/issued/zdzlcs/Weizhihuibao'
						),
					},{
						path: '/baojingcanshu',
						name: 'baojingcanshu',
						meta: { title: '报警参数' },
						component: load(
							'zhjk/issued/zdzlcs/Baojingcanshu'
						),
					},{
						path: '/daoluyunshu',
						name: 'daoluyunshu',
						meta: { title: '道路运输证IC卡认证' },
						component: load(
							'zhjk/issued/zdzlcs/Daoluyunshu'
						),
					},{
						path: '/paizhaozhaopian',
						name: 'paizhaozhaopian',
						meta: { title: '拍照照片参数' },
						component: load(
							'zhjk/issued/zdzlcs/Paizhaozhaopian'
						),
					},{
						path: '/cheliangcanshu',
						name: 'cheliangcanshu',
						meta: { title: '车辆参数' },
						component: load(
							'zhjk/issued/zdzlcs/Cheliangcanshu'
						),
					},{
						path: '/zhongduanshuxing',
						name: 'zhongduanshuxing',
						meta: { title: '终端属性查询' },
						component: load(
							'zhjk/issued/zdzlcs/Zhongduanshuxing'
						),
					},{
						path: '/gnss',
						name: 'gnss',
						meta: { title: 'GNSS参数' },
						component: load(
							'zhjk/issued/zdzlcs/Gnss'
						),
					},{
						path: '/shujuxiaxing',
						name: 'shujuxiaxing',
						meta: { title: '数据下行透传' },
						component: load(
							'zhjk/issued/zdzlcs/Shujuxiaxing'
						),
					},{
						path: '/zhongduanbaojingpingbi',
						name: 'zhongduanbaojingpingbi',
						meta: { title: '终端报警屏蔽' },
						component: load(
							'zhjk/issued/zdzlcs/Zhongduanbaojingpingbi'
						),
					},{
						path: '/chakanjiashiyuan',
						name: 'chakanjiashiyuan',
						meta: { title: '查看驾驶员' },
						component: load(
							'zhjk/issued/zdzlcs/Chakanjiashiyuan'
						),
					},{//多媒体指令
						path: '/paizhao',
						name: 'paizhao',
						meta: { title: '拍照' },
						component: load(
							'zhjk/issued/dmtzl/Paizhao'
						),
					},{
						path: '/yuyinjianting',
						name: 'yuyinjianting',
						meta: { title: '语音监听' },
						component: load(
							'zhjk/issued/dmtzl/Yuyinjianting'
						),
					},{
						path: '/luxiang',
						name: 'luxiang',
						meta: { title: '录像' },
						component: load(
							'zhjk/issued/dmtzl/Luxiang'
						),
					},{
						path: '/luyin',
						name: 'luyin',
						meta: { title: '录音' },
						component: load(
							'zhjk/issued/dmtzl/Luyin'
						),
					},{
						path: '/shujujiansuo',
						name: 'shujujiansuo',
						meta: { title: '存储多媒体数据检索' },
						component: load(
							'zhjk/issued/dmtzl/Shujujiansuo'
						),
					},{
						path: '/shujushangchuan',
						name: 'shujushangchuan',
						meta: { title: '存储多媒体数据上传' },
						component: load(
							'zhjk/issued/dmtzl/Shujushangchuan'
						),
					},{
						path: '/meitishangchuan',
						name: 'meitishangchuan',
						meta: { title: '单条存储多媒体上传' },
						component: load(
							'zhjk/issued/dmtzl/Meitishangchuan'
						),
					},{//定位指令
						path: '/linshigengzong',
						name: 'linshigengzong',
						meta: { title: '临时跟踪' },
						component: load(
							'zhjk/issued/dwzl/Lingshigenzong'
						),
					},{
						path: '/shangchuanweihzi',
						name: 'shangchuanweihzi',
						meta: { title: '上传位置' },
						component: load(
							'zhjk/issued/dwzl/Shangchuanweihzi'
						),
					},{//信息指令
						path: '/wenbenxinxixiafa',
						name: 'wenbenxinxixiafa',
						meta: { title: '文本信息下发' },
						component: load(
							'zhjk/issued/xxzl/Wenbenxinxixiafa'
						),
					},{
						path: '/wenbenxinxixiafaTwo',
						name: 'wenbenxinxixiafaTwo',
						meta: { title: '文本信息下发2019' },
						component: load(
							'zhjk/issued/xxzl/WenbenxinxixiafaTwo'
						),
					},{
						path: '/tiwenxiafa',
						name: 'tiwenxiafa',
						meta: { title: '提问下发' },
						component: load(
							'zhjk/issued/xxzl/Tiwenxiafa'
						),
					},{
						path: '/shijianbaogao',
						name: 'shijianbaogao',
						meta: { title: '事件报告设置' },
						component: load(
							'zhjk/issued/xxzl/Shijianbaogao'
						),
					},{
						path: '/xinximenu',
						name: 'xinximenu',
						meta: { title: '信息菜单设置' },
						component: load(
							'zhjk/issued/xxzl/Xinxicandan'
						),
					},{
						path: '/callback',
						name: 'callback',
						meta: { title: '电话回拨' },
						component: load(
							'zhjk/issued/xxzl/Callback'
						),
					},{ //视频指令
						path: '/yinshipinshuxing',
						name: 'yinshipinshuxing',
						meta: { title: '音视频属性查询' },
						component: load(
							'zhjk/issued/spzl/Yinshipinshuxing'
						),
					},{
						path: '/yinshipincanshu',
						name: 'yinshipincanshu',
						meta: { title: '音视频参数' },
						component: load(
							'zhjk/issued/spzl/Yinshipincanshu'
						),
					},{
						path: '/yinshipintongdao',
						name: 'yinshipintongdao',
						meta: { title: '音视频通道列表' },
						component: load(
							'zhjk/issued/spzl/Yinshipintongdao'
						),
					},{
						path: '/dandutongdaoshipin',
						name: 'dandutongdaoshipin',
						meta: { title: '单独通道视频参数' },
						component: load(
							'zhjk/issued/spzl/Dandutongdaoshipin'
						),
					},{
						path: '/teshubaojing',
						name: 'teshubaojing',
						meta: { title: '特殊报警录像参数' },
						component: load(
							'zhjk/issued/spzl/Teshubaojing'
						),
					},{
						path: '/baojingpingbi',
						name: 'baojingpingbi',
						meta: { title: '报警屏蔽' },
						component: load(
							'zhjk/issued/spzl/Baojingpingbi'
						),
					},{
						path: '/tuxiangfenxi',
						name: 'tuxiangfenxi',
						meta: { title: '图像分析报警参数' },
						component: load(
							'zhjk/issued/spzl/Tuxiangfenxi'
						),
					},{
						path: '/zhongduanxiumian',
						name: 'zhongduanxiumian',
						meta: { title: '终端休眠唤醒模式' },
						component: load(
							'zhjk/issued/spzl/Zhongduanxiumian'
						),
					},{//区域指令
						path: '/yuanxing',
						name: 'yuanxing',
						meta: { title: '圆形区域设置' },
						component: load(
							'zhjk/issued/qyzl/Yuanxing'
						),
					},{
						path: '/juxing',
						name: 'juxing',
						meta: { title: '矩形区域设置' },
						component: load(
							'zhjk/issued/qyzl/Juxing'
						),
					},{
						path: '/duobianxing',
						name: 'duobianxing',
						meta: { title: '多边形区域设置' },
						component: load(
							'zhjk/issued/qyzl/Duobianxing'
						),
					},{
						path: '/luxian',
						name: 'luxian',
						meta: { title: '路线设置' },
						component: load(
							'zhjk/issued/qyzl/Luxian'
						),
					},{//记录仪指令
						path: '/xingshijilucanshuchaxun',
						name: 'xingshijilucanshuchaxun',
						meta: { title: '行驶记录参数查询' },
						component: load(
							'zhjk/issued/jlyzl/Xingshijilucanshuchaxun'
						),
					},{
						path: '/xingshijilucanshushezhi',
						name: 'xingshijilucanshushezhi',
						meta: { title: '行驶记录参数设置' },
						component: load(
							'zhjk/issued/jlyzl/Xingshijilucanshushezhi'
						),
					},{//主动安全
						path: '/zhudonganquan',
						name: 'zhudonganquan',
						meta: { title: '主动安全基本信息查询' },
						component: load(
							'zhjk/issued/zdaq/Zhudonganquan'
						),
					},{
						path: '/gaojijiashi',
						name: 'gaojijiashi',
						meta: { title: '高级驾驶辅助系统参数设置' },
						component: load(
							'zhjk/issued/zdaq/Gaojijiashi'
						),
					},{
						path: '/jiashiyuan',
						name: 'jiashiyuan',
						meta: { title: '驾驶员状态监测系统参数设置' },
						component: load(
							'zhjk/issued/zdaq/Jiashiyuan'
						),
					},{
						path: '/taiyajiance',
						name: 'taiyajiance',
						meta: { title: '胎压监测系统参数设置' },
						component: load(
							'zhjk/issued/zdaq/Taiyajiance'
						),
					},{
						path: '/mangqujiance',
						name: 'mangqujiance',
						meta: { title: '盲区监测系统参数设置' },
						component: load(
							'zhjk/issued/zdaq/Mangqujiance'
						),
					},{//其他指令
						path: '/dianhuaben',
						name: 'dianhuaben',
						meta: { title: '电话本设置' },
						component: load(
							'zhjk/issued/qtzl/Dianhuaben'
						),
					},{
						path: '/shijian',
						name: 'shijian',
						meta: { title: '事件设置' },
						component: load(
							'zhjk/issued/qtzl/Shijian'
						),
					},{
						path: '/caidan',
						name: 'caidan',
						meta: { title: '信息点播菜单设置' },
						component: load(
							'zhjk/issued/qtzl/Caidan'
						),
					},{
						path: '/shujutouchuan',
						name: 'shujutouchuan',
						meta: { title: '数据透传' },
						component: load(
							'zhjk/issued/qtzl/Shujutouchuan'
						),
					},{
						path: '/rsa',
						name: 'rsa',
						meta: { title: 'RSA公钥' },
						component: load(
							'zhjk/issued/qtzl/Rsa'
						),
					},{
						path: '/shengjibao',
						name: 'shengjibao',
						meta: { title: '下发终端升级包' },
						component: load(
							'zhjk/issued/qtzl/Shengjibao'
						),
					},{//2012版行车记录仪
						path: '/zhixingbiaozhun',
						name: 'zhixingbiaozhun',
						meta: { title: '采集记录仪执行标准版本' },
						component: load(
							'zhjk/issued/xcylytwo/Zhixingbiaozhun'
						),
					},{
						path: '/jiashirenxinxi',
						name: 'jiashirenxinxi',
						meta: { title: '采集当前驾驶人信息' },
						component: load(
							'zhjk/issued/xcylytwo/Jiashirenxinxi'
						),
					},{
						path: '/shishishijian',
						name: 'shishishijian',
						meta: { title: '采集记录仪的实时时间' },
						component: load(
							'zhjk/issued/xcylytwo/Shishishijian'
						),
					},{
						path: '/xingshilicheng',
						name: 'xingshilicheng',
						meta: { title: '采集累计行驶里程' },
						component: load(
							'zhjk/issued/xcylytwo/Xingshilicheng'
						),
					},{
						path: '/maichongxishu',
						name: 'maichongxishu',
						meta: { title: '采集记录仪脉冲系数' },
						component: load(
							'zhjk/issued/xcylytwo/Maichongxishu'
						),
					},{
						path: '/cheliangxinxi',
						name: 'cheliangxinxi',
						meta: { title: '采集车辆的信息' },
						component: load(
							'zhjk/issued/xcylytwo/Cheliangxinxi'
						),
					},{
						path: '/zhuangtaixinhaopeizhi',
						name: 'zhuangtaixinhaopeizhi',
						meta: { title: '采集记录仪状态信号配置信息' },
						component: load(
							'zhjk/issued/xcylytwo/Zhuangtaixinhaopeizhi'
						),
					},{
						path: '/weiyixingbianhao',
						name: 'weiyixingbianhao',
						meta: { title: '采集记录仪唯一性编号' },
						component: load(
							'zhjk/issued/xcylytwo/Weiyixingbianhao'
						),
					},{
						path: '/xingshisudu',
						name: 'xingshisudu',
						meta: { title: '采集指定的行驶速度记录' },
						component: load(
							'zhjk/issued/xcylytwo/Xingshisudu'
						),
					},{
						path: '/weizhixinxi',
						name: 'weizhixinxi',
						meta: { title: '采集指定的位置信息记录' },
						component: load(
							'zhjk/issued/xcylytwo/Weizhixinxi'
						),
					},{
						path: '/shiguyidian',
						name: 'shiguyidian',
						meta: { title: '采集指定的事故疑点记录' },
						component: load(
							'zhjk/issued/xcylytwo/Shiguyidian'
						),
					},{
						path: '/chaoshijiashi',
						name: 'chaoshijiashi',
						meta: { title: '采集指定的超时驾驶记录' },
						component: load(
							'zhjk/issued/xcylytwo/Chaoshijiashi'
						),
					},{
						path: '/jiashirenshenfen',
						name: 'jiashirenshenfen',
						meta: { title: '采集指定的驾驶人身份记录' },
						component: load(
							'zhjk/issued/xcylytwo/Jiashirenshenfen'
						),
					},{
						path: '/waibugongdian',
						name: 'waibugongdian',
						meta: { title: '采集指定的记录仪外部供电记录' },
						component: load(
							'zhjk/issued/xcylytwo/Waibugongdian'
						),
					},{
						path: '/canshuxiugai',
						name: 'canshuxiugai',
						meta: { title: '采集指定的记录仪参数修改记录' },
						component: load(
							'zhjk/issued/xcylytwo/Canshuxiugai'
						),
					},{
						path: '/zhuangtairizhi',
						name: 'zhuangtairizhi',
						meta: { title: '采集指定的速度状态日志' },
						component: load(
							'zhjk/issued/xcylytwo/Zhuangtairizhi'
						),
					},{
						path: '/canshushezhi',
						name: 'canshushezhi',
						meta: { title: '参数设置' },
						component: load(
							'zhjk/issued/xcylytwo/Canshushezhi'
						),
					}],
				},{
					path: "/offline",
					name: "offline",
					meta: { title: "离线待发" },
					component: load("zhjk/issued/Offline"),
				},{
					path: "/sendHistory",
					name: "sendHistory",
					meta: { title: "发送历史" },
					component: load("zhjk/issued/SendHistory"),
				},
				]
				
			},{
				path: '/multiMedia',
				name: 'multiMedia',
				meta: { title: '多媒体' }, 
				component: load('zhjk/MultiMedia'),  
			},{
				path: '/multimediaEvents',
				name: 'multimediaEvents',
				meta: { title: '多媒体事件' }, 
				component: load('zhjk/MultimediaEvents'),  
			},{
				path: '/offlineHandle',
				name: 'offlineHandle',
				meta: { title: '离线处置',status: "0" }, 
				component: load('zhjk/OfflineHandle'),  
			},{
				path: '/checkPostNotice',
				name: 'checkPostNotice',
				meta: { title: '查岗通知'}, 
				component: load('zhjk/CheckPostNotice'),  
			},{
				path: '/alarmHandle',
				name: 'alarmHandle',
				meta: { title: '报警督办' }, 
				component: load('zhjk/AlarmHandle'),  
			},{
				path: '/abnormalPostCar',
				name: 'abnormalPostCar',
				meta: { title: '异常定位车辆',status: "0" }, 
				component: load('zhjk/AbnormalPostCar'),  
			},{
				path: '/superiorAlarmWarn',
				name: 'superiorAlarmWarn',
				meta: { title: '上级报警预警'}, 
				component: load('zhjk/SuperiorAlarmWarn'),  
			},{
				path: '/reportAlarmWarn',
				name: 'reportAlarmWarn',
				meta: { title: '上报报警预警',status: "0" }, 
				component: load('zhjk/ReportAlarmWarn'),  
			},{
				path: '/deviceTcInfo',
				name: 'deviceTcInfo',
				meta: { title: '设备透传消息',status: "0" }, 
				component: load('zhjk/DeviceTcInfo'),  
			},{
				path: '/electronLabelRecord',
				name: 'electronLabelRecord',
				meta: { title: '电子标签记录',status: "0" }, 
				component: load('zhjk/ElectronLabelRecord'),  
			}
		],
	},{ //业务管理
		path: '/main',
		name: 'workMenagement',
		meta: { title: '业务管理' },
		component: load('Main'),
		children: [{
				path: '/vehicleMan',
				name: 'vehicleMan',
				meta: { title: '车辆管理' },
				component: load('workMenagement/VehicleMan'),
			},{
				path: "/ruleManage",
				name: "ruleManage",
				meta: { title: "规则管理" },
				component: load("workMenagement/RuleManage"),
			},{
				path: '/electronLabelMan',
				name: 'electronLabelMan',
				meta: { title: '电子标签管理' },
				component: load('workMenagement/ElectronLabelMan'),
			},{
				path: '/areaMonitor',
				name: 'areaMonitor',
				meta: { title: '区域监控' },
				component: load('workMenagement/AreaMonitor'),
			},{
				path: '/alarmRules',
				name: 'alarmRules',
				meta: { title: '平台报警规则' },
				component: load('workMenagement/AlarmRules'),
			},{
				path: '/oilSensor',
				name: 'oilSensor',
				meta: { title: '油量传感器' },
				component: load('workMenagement/OilSensor'),
			},{
				path: '/terminalModelMan',
				name: 'terminalModelMan',
				meta: { title: '终端型号管理' },
				component: load('workMenagement/TerminalModelMan'),
			},{
				path: '/cronJob',
				name: 'cronJob',
				meta: { title: '定时任务' },
				component: load('workMenagement/CronJob'),
			}
		],
	},{ //统计报表
		path: '/main',
		name: 'statReport',
		meta: { title: '统计报表' },
		component: load('Main'),
		children:[{
			path: '/monitoringReport',
			name: 'monitoringReport',
			redirect: '/onDutyReport',
			meta: { title: '监管报表' },
			component: load('statReport/MonitoringReport'),
			children: [{
				path: '/onDutyReport',
				name: 'onDutyReport',
				meta: { title: '动态监控值班记录表' },
				component: load(
					'statReport/component/OnDutyReport'
				),
			},{
				path: '/breakRulesReport',
				name: 'breakRulesReport',
				meta: { title: '车辆动态监控违章记录' },
				component: load(
					'statReport/component/BreakRulesReport'
				),
			},{
				path: '/spotCheckReport',
				name: 'spotCheckReport',
				meta: { title: '动态监控抽查表' },
				component: load(
					'statReport/component/SpotCheckReport'
				),
			},{
				path: '/serviceSpotCheckReport',
				name: 'serviceSpotCheckReport',
				meta: { title: '服务商动态监控抽查表' },
				component: load(
					'statReport/component/ServiceSpotCheckReport'
				),
			},{
				path: '/monthlyReport',
				name: 'monthlyReport',
				meta: { title: '动态监控月报表' },
				component: load(
					'statReport/component/MonthlyReport'
				),
			},{
				path: '/enterpriseViolationReport',
				name: 'enterpriseViolationReport',
				meta: { title: '企业违章统计报表' },
				component: load(
					'statReport/component/EnterpriseViolationReport'
				),
			},{
				path: '/vehicleViolationReport',
				name: 'vehicleViolationReport',
				meta: { title: '车辆违章统计报表' },
				component: load(
					'statReport/component/VehicleViolationReport'
				),
			},{
				path: '/offlineVehicleStatisticsReport',
				name: 'offlineVehicleStatisticsReport',
				meta: { title: '离线车辆统计报表' },
				component: load('statReport/component/OfflineReport')
			},{
				path: '/offlineVehicleDisposalRecord',
				name: 'offlineVehicleDisposalRecord',
				meta: { title: '离线车辆处置记录' },
				component: load(
					'statReport/component/OfflineVehicleDisposalRecord'
				),
			},{
				path: '/dynamicMonitoringDailyReport',
				name: 'dynamicMonitoringDailyReport',
				meta: { title: '动态监控日巡查表' },
				component: load(
					'statReport/component/DynamicMonitoringDailyReport'
				),
			},{
				path: '/earlyMorningReport',
				name: 'earlyMorningReport',
				meta: { title: '凌晨营运提醒报表' },
				component: load(
					'statReport/component/EarlyMorningReport'
				),
			},{
				path: '/qualifiedRateReport',
				name: 'qualifiedRateReport',
				meta: { title: '定位合格率报表' },
				component: load(
					'statReport/component/QualifiedRateReport'
				),
			},
			{
				path: '/driftIntegrityRateReport',
				name: 'driftIntegrityRateReport',
				meta: { title: '定位漂移完整率报表' },
				component: load(
					'statReport/component/DriftIntegrityRateReport'
				),
			}],
		},{
			path: "/checkReport",
			name: "checkReport",
			redirect: '/historyLocation',
			meta: { title: "报表查询" },
			component: load("checkCenter/CheckReport"),
			children: [
			  {
				path: "/historyLocation",
				name: "historyLocation",
				meta: { title: "历史位置" },
				component: load("checkCenter/component/locationReport/HistoryLocation")
			  },{
				path: "/historyLine",
				name: "historyLine",
				meta: { title: "历史轨迹" },
				component: load("checkCenter/component/locationReport/HistoryLine")
			  },{
				path: "/milesDay",
				name: "milesDay",
				meta: { title: "里程统计（天）" },
				component: load("checkCenter/component/mileReport/MilesDay")
			  },{
				path: "/milesMinute",
				name: "milesMinute",
				meta: { title: "里程统计（分）" },
				component: load("checkCenter/component/mileReport/MilesMinute")
			  },{
				path: "/alarmAnalysis",
				name: "alarmAnalysis",
				meta: { title: "报警统计分析" },
				component: load("checkCenter/component/alarmReport/AlarmAnalysis")
			  },{
				path: "/alarmDayReport",
				name: "alarmDayReport",
				meta: { title: "报警日统计分析" },
				component: load("checkCenter/component/alarmReport/AlarmDayReport")
			  },{
				path: "/alarmProcesseRate",
				name: "alarmProcesseRate",
				meta: { title: "报警处理率" },
				component: load("checkCenter/component/alarmReport/AlarmProcesseRate")
			  },{
				path: "/onOffAnalysis",
				name: "onOffAnalysis",
				meta: { title: "上下线统计分析" },
				component: load("checkCenter/component/statusReport/OnOffAnalysis")
			  },{
				path: "/ACC-Analysis",
				name: "ACC-Analysis",
				meta: { title: "ACC统计分析" },
				component: load("checkCenter/component/statusReport/AccAnalysis")
			  },{
				path: "/driverInOutAnalysis",
				name: "driverInOutAnalysis",
				meta: { title: "司机登退签统计分析" },
				component: load("checkCenter/component/driverReport/DriverInOutAnalysis")
			  },{
				path: "/driverStatusAnalysis",
				name: "driverStatusAnalysis",
				meta: { title: "司机状态分析" },
				component: load("checkCenter/component/driverReport/DriverStatusAnalysis")
			  },{
				path: "/driverIdentifiyReport",
				name: "driverIdentifiyReport",
				meta: { title: "司机身份识别结果历史报表" },
				component: load("checkCenter/component/driverReport/DriverIdentifiyReport")
			  },{
				path: "/driveAnalysis",
				name: "driveAnalysis",
				meta: { title: "行车统计分析" },
				component: load("checkCenter/component/carDriveReport/DriveAnalysis")
			  },{
				path: "/parkAnalysis",
				name: "parkAnalysis",
				meta: { title: "停车统计分析" },
				component: load("checkCenter/component/carDriveReport/ParkAnalysis")
			  },{
				path: "/carInOutAnalysis",
				name: "carInOutAnalysis",
				meta: { title: "车辆登退签统计分析" },
				component: load("checkCenter/component/carDriveReport/CarInOutAnalysis")
			  }
			],
		},{
			path: "/monitoringParameter",
			name: "monitoringParameter",
			meta: { title: "监控台账" },
			redirect: '/onlineAccount',
			component: load("statReport/MonitoringParameter"),
			children: [
			  {
				path: "/onlineAccount",
				name: "onlineAccount",
				meta: { title: "每日在线台账" },
				component: load("statReport/monitoringParameter/OnlineAccount"),
			  },{
				path: "/alarmLedger",
				name: "alarmLedger",
				meta: { title: "每日车辆违法报警台账" },
				component: load("statReport/monitoringParameter/AlarmLedger"),
			  },{
				path: "/offlineAccount",
				name: "offlineAccount",
				meta: { title: "每日离线台账" },
				component: load("statReport/monitoringParameter/OfflineAccount"),
			  },{
				path: "/dynamicMonitoringDutyLog",
				name: "dynamicMonitoringDutyLog",
				meta: { title: "车辆动态监管值班日志表" },
				component: load("statReport/monitoringParameter/VehicleDutyLog"),
			  },{
				path: "/monthlyDynamicAnalysisReport",
				name: "monthlyDynamicAnalysisReport",
				meta: { title: "月度动态分析报表" },
				component: load("statReport/monitoringParameter/MonthlyReport"),
			  }
			]  
		},{
			path: "/logbook",
			name: "logbook",
			meta: { title: "行车日志" },
			component: load("statReport/Logbook"),
		},{
			path: "/safetyChecklist",
			name: "safetyChecklist",
			meta: { title: "车辆安全检查表" },
			component: load("statReport/SafetyChecklist"),
		},{
			path: "/rota",
			name: "rota",
			meta: { title: "24小时值班表" },
			component: load("statReport/Rota"),
		}]
		
	},{ //系统管理
		path: '/main',
		name: 'systemCenter',
		meta: { title: '系统管理' },
		component: load('Main'),
		children: [{
				path: '/companyMan',
				name: 'companyMan',
				meta: { title: '公司管理' },
				component: load('systemCenter/CompanyMan'),
			},{
				path: '/userMan',
				name: 'userMan',
				meta: { title: '用户管理' },
				component: load('systemCenter/UserMan'),
			},{
				path: '/roleMan',
				name: 'roleMan',
				meta: { title: '角色管理' },
				component: load('systemCenter/RoleMan'),
			},{
				path: '/nodeMan',
				name: 'nodeMan',
				meta: { title: '节点管理' },
				component: load('systemCenter/NodeMan'),
			},{
				path: '/systemDict',
				name: 'systemDict',
				meta: { title: '系统字典' },
				component: load('systemCenter/SystemDict'),
			},
			{
				path: '/upgrade',
				name: 'upgrade',
				meta: { title: '终端升级包' },
				component: load('systemCenter/Upgrade'),
			},{
				path: '/softVersion',
				name: 'softVersion',
				meta: { title: '软件版本管理' },
				component: load('systemCenter/SoftVersion'),
			},{
				path: '/higherLevel',
				name: 'higherLevel',
				meta: { title: '上级平台管理' },
				component: load('systemCenter/HigherLevel'),
			},{
				path: '/abutment',
				name: 'abutment',
				meta: { title: '部标对接平台' },
				component: load('systemCenter/Abutment'),
			},
			{
				path: '/loginLog',
				name: 'loginLog',
				meta: { title: '用户登录日志' },
				component: load('systemCenter/LoginLog'),
			},
			{
				path: '/operateLog',
				name: 'operateLog',
				meta: { title: '用户操作日志' },
				component: load('systemCenter/OperateLog'),
			}
		],
	},{ //智慧看板
		path: '/main',
		name: 'zhkb',
		meta: { title: '智慧看板' },
		component: load('Main'),
		children: [{
            path: "/bigDataStatics",
            name: "bigDataStatics",
            meta: { title: "大数据统计一" },
            component: load("dataCenter/bigDataStatics/BigDataStatics"),
          },
          {
            path: "/enterpriseBigScreen",
            name: "enterpriseBigScreen",
            meta: { title: "企业综合大屏" },
            component: load("dataCenter/enterpriseBigScreen/EnterpriseBigScreen"),
          },{
				path: "/bigDataAnalysis",
				name: "bigDataAnalysis",
				meta: { title: "安全服务平台" },
				component: load("dataCenter/bigDataAnalysis/BigDataAnalysis"),
		  }
		],
	},{ //运营管理
		path: '/main',
		name: 'yygl',
		meta: { title: '运营管理' },
		component: load('Main'),
		children: [{
				path: "/smsRecharge",
				name: "smsRecharge",
				meta: { title: "短信充值" },
				component: load("operate/SmsRecharge"),
			},{
				path: "/smsConfig",
				name: "smsConfig",
				meta: { title: "短信充值" },
				component: load("operate/SmsConfig"),
			}
		],
	}
]
const router = new VueRouter({
	mode: 'history',
	//动态环境路由配置,该地址来源vue.config.js publicPath
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	if (to.meta.status && to.meta.status == 0) {
		// elMessage('正在开发中,敬请期待!','info')
		next(false);
	} else {
	  // 免登陆ak
		var ak = to.query.ak;
		if (ak){
			// 把ak缓存起来
			localStorage.setItem('accessKey',ak)
			// 存储一个token值，跟ak的值一样，防止一直跳转到登录页面
			localStorage.setItem('token',ak)
			next()
		} else {
			window.document.title = to.meta.title || '登录'
			let token = localStorage.getItem('token');
			// 过滤登录页，防止死循环
			if (!token && to.name !== 'login') {
				next({ name: 'login' })
			} else {
				next()
			}
		}
	}
})
export default router