import Vue from 'vue'
import Vuex from 'vuex'
import baseData from './baseData'
import mapConfig from './mapConfig'
import bigScreen from './bigScreen'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    baseData,
    mapConfig,
    bigScreen
  },
})
