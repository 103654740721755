<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
// 解决ERROR ResizeObserver loop completed with undelivered notifications.

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);

    super(callback);
  }
};
export default {
  data() {
    return {
      
    };
  },
  methods: {
  },
  created() {
    
  },
};
</script>

<style lang="less">
html,body{
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#app {
  width: 100vw;
  height: 100vh;
  background: #F5F7FA;
  overflow: hidden;
}

</style>