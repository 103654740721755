import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/icons/index' //svg图标

// 全局配置
import '@/assets/css/reset.less'
import '@/assets/iconfont/iconfont.css' // 引入iconfont

// 第三方包
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
import * as XLSX from 'xlsx'
Vue.prototype.XLSX = XLSX
import vuescroll from 'vuescroll'
Vue.use(vuescroll, {
	ops: {
		bar: {
			background: '#c1c1c1',
			keepShow: true,
		},
	}, // 在这里设置全局默认配置
})
// 接口引用
import axiosApi from '@/utils/request'
Vue.use(axiosApi)
// 大屏引用的组件
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
// 时间转化问题
import { toTimeFun } from "@/utils/time";
Vue.prototype.toTimeFun = toTimeFun

//ztree
import $ from "jquery";
import "ztree";
import "ztree/css/metroStyle/metroStyle.css";

import "@/assets/ztree/js/jquery.ztree.test.js"; //自定义控件引入的js
import "@/assets/ztree/js/jquery.ztree.excheck.min.js"; //复选框

//vex-table
import 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)
import 'vxe-table/lib/style.css'
import '@/assets/css/vex-table-overwrite.less' //重写vex-table表格样式 控制头部颜色，字体大小
Vue.use(VXETable)

const vm = new Vue({
	router,
	store,
	render: (h) => h(App),
	created() {
		// store.commit('addMenu', router)
	},
}).$mount('#app')

window.vm = vm
