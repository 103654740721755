// 时间戳转化为时间
export function toTimeFun(time){
  let date = new Date(parseInt(time) * 1000); // 此处时间戳以毫秒为单位
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  return  Year + '-' + Moth + '-' + Day + '   '+ Hour +':'+ Minute  + ':' + Sechond;
}

export function fortTime(date,fmt){
  let o = {
      "M+": date.getMonth() + 1,                 //月份
      "d+": date.getDate(),                    //日
      "H+": date.getHours(), //小时
      "h+": date.getHours() > 12 ? date.getHours() -12 :date.getHours(), //小时
      "m+": date.getMinutes(),                 //分
      "s+": date.getSeconds(),                 //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds()             //毫秒
  };

  if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
      if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
export function dateFormat(time) {
  let result = parseInt(time)
  let day= Math.floor(result / 86400) ?  Math.floor(result / 86400)+'天' :'';
  let h = Math.floor(result / 3600) ?  Math.floor(result / 3600)+'时' :'';
  let m = Math.floor((result / 60 % 60)) ?  Math.floor((result / 60 % 60))+'分' : '';
  let s = Math.floor((result % 60))  ? Math.floor((result % 60))+'秒' : '';
  // 拼接时间
  return day+h+m+s;
}
export function formatSeconds(value) { 
    var theTime = parseInt(value);// 需要转换的时间秒 
    var theTime1 = 0;// 分 
    var theTime2 = 0;// 小时 
    var theTime3 = 0;// 天
    if(theTime > 60) { 
     theTime1 = parseInt(theTime/60); 
     theTime = parseInt(theTime%60); 
     if(theTime1 > 60) { 
      theTime2 = parseInt(theTime1/60); 
      theTime1 = parseInt(theTime1%60); 
      if(theTime2 > 24){
       //大于24小时
       theTime3 = parseInt(theTime2/24);
       theTime2 = parseInt(theTime2%24);
      }
     } 
    } 
    var result = '';
    if(theTime > 0){
     result = ""+parseInt(theTime)+"秒";
    }
    if(theTime1 > 0) { 
     result = ""+parseInt(theTime1)+"分"+result; 
    } 
    if(theTime2 > 0) { 
     result = ""+parseInt(theTime2)+"小时"+result; 
    } 
    if(theTime3 > 0) { 
     result = ""+parseInt(theTime3)+"天"+result; 
    }
    return result; 
   }
export function dateFormat1(time) {
    let result = parseInt(time)
    let h = Math.floor(result / 3600) ?  Math.floor(result / 3600)+'时' :'';
    let m = Math.floor((result / 60 % 60)) ?  Math.floor((result / 60 % 60))+'分' : '';
    let s = Math.floor((result % 60))  ? Math.floor((result % 60))+'秒' : '';
    // 拼接时间
    return h+m+s;
}
export function SecondToDate(value) {
    var result = ""
    var sencond = parseInt(value)
    //3600秒等于60分钟等于1小时
    if(sencond > 3600 * 24){  //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24);    //求天数整除外剩余的秒数

        var hour = sencond / 3600;          //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        var minute = sencond % 3600 / 60; 
        var sec = sencond % 60;
        result = parseInt(day)+"天"+parseInt(hour)+"时"+parseInt(minute)+"分"+parseInt(sec)+"秒";
    } else if(sencond > 3600){     //表示大于一个小时
        var hour = sencond / 3600;
        var minute = sencond % 3600 / 60;         //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        var sec = sencond % 60;
        result = parseInt(hour)+"时"+parseInt(minute)+"分"+parseInt(sec)+"秒";
    } else if(sencond > 60){  //表示大于一分钟
        var minute = sencond / 60;
        var sec = sencond % 60;           //求分钟数整除外剩余的秒数
        result = parseInt(minute)+"分"+parseInt(sec)+"秒";
    } else {  //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result = ""+parseInt(sencond)+"秒";
    }
    return result;
}
export function formatDate(date,format){
    if(!date){
        date = new Date();
    }
    let year = date.getFullYear();
    let month = date.getMonth()+1
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();
    let scend = date.getSeconds();
    if(!format || format ==""){
        format ="yyyy-MM-dd HH:mm:ss";
    }
    return format.replace("yyyy",year).replace("MM",month>=10?month:"0"+month).replace("dd",day>=10?day:"0"+day).replace("HH",hour>=10?hour:"0"+hour)
    .replace("mm",min>=10?min:"0"+min).replace("ss",scend>=10?scend:"0"+scend);
}
