export default {
    state: {
        //控制左侧面板锁定
        isPanelOpen: true,
        firstPage:'/bigDataStatics',
        loopScreenData:['bigDataStatics', 'enterpriseBigScreen', 'platformBigScreen','focusCenter','operationDashboard', 'activeSafetyVideo', 'carIllegalAnalysis', 'companyIllegalAnalysis', 'illegalDriveList', 'carDataStastic','carAreaStastic','geoDistributeMap',],
        loopTime:5,
        geoName:'中国',
        isShowLogo:1,
        screenInfo:{},//综合大屏数据
        screenMap:[],//综合大屏地图数据
    },
    getters: {
        isPanelOpen: state => state.isPanelOpen,
        firstPage: state => state.firstPage,
        loopScreenData: state => state.loopScreenData,
        loopTime: state => state.loopTime,
        geoName: state => state.geoName,
        isShowLogo: state => state.isShowLogo,
    },
    mutations: {
        changeScreenInfo(state,value){ 
            state.screenInfo = value; 
        },
        changeScreenMap(state,value){ 
            state.screenMap = value; 
        },
        savePanelOpen(state, data) {
            state.isPanelOpen = data;
        },
        saveFirstPage(state, data) {
            state.firstPage = data;
        },
        saveLoopPage(state, data) {
            state.loopScreenData = data;
        },
        saveLoopTime(state, data) {
            state.loopTime = data;
        },
        saveGeoName(state, data) {
            state.geoName = data;
        },
    },
    actions: {},
}